const acc = document.getElementsByClassName('card accordion_expandable')
const accContent = document.getElementsByClassName('accordion-content')

if (acc.length > 0 && accContent.length > 0) {
  accContent[0].style.maxHeight = accContent[0].scrollHeight + 'px'

  for (let i = 0; i < acc.length; i++) {
    acc[i].addEventListener('click', function () {
      const content = accContent[i]
      content.style.maxHeight = content.scrollHeight + 'px'

      for (let j = 0; j < accContent.length; j++) {
        if (accContent[j].style.maxHeight && j !== i) {
          accContent[j].style.maxHeight = null
        }
      }
    })
  }
}
